import './projects.css';

const Project = ({ index, title, content, url }) => {
  return (
    <div className='project-container'>
      <div className='project-index'>{index}</div>
      <a href={url} target='_blank'>
        <div className='project-title'>{title}</div>
      </a>
      <div className='project-content'>{content}</div>
    </div>
  );
};

export default Project;
