import './intro.css';

const Intro = () => {
  return (
    <div className='intro'>
      A software engineer building products with impact, creating experiences,
      and achieving value driven results.
    </div>
  );
};

export default Intro;
