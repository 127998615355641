import './skills.css';
import React, { useState } from 'react';

const Accordion = ({ title, description }) => {
  const [isActive, setIsActive] = useState(false);
  return (
    <div className='accordion-item'>
      <div
        onClick={() => {
          setIsActive(!isActive);
        }}
        className='accordion-title'
      >
        <div>{title}</div>
        <div>{isActive ? '-' : '+'}</div>
      </div>

      {isActive && (
        <div className='accordian-content'>
          <p>{description}</p>
        </div>
      )}
    </div>
  );
};

export default Accordion;
