import './experience.css';

const Experience = () => {
  const experienceData = [
    {
      date: 'May 2022 - Sep 2022',
      title: (
        <span>
          Mobile Developer Intern
          <br />
          Royal Bank of Canada
        </span>
      ),
      description: (
        <ul>
          <li>
            Developed the Wealth Management banking application allowing
            customers to manage investments, utilizing Kotlin for Android and
            Swift for iOS applications.
          </li>
          <li>
            Collaborated with team members through SCRUM events, code reviews,
            and Git version control to deliver a robust user experience handling
            15 million USD per month in cheque deposits
          </li>
        </ul>
      ),
    },
    {
      date: 'Sep 2021 - Apr 2022',
      title: (
        <span>
          Full Stack Web Developer Intern
          <br /> Royal Bank of Canada
        </span>
      ),
      description: (
        <ul>
          <li>
            Developed the banking web application using Javascript + Angular for
            the front end, and Node + Java/Springboot for the backend.
          </li>
          <li>
            Identified and resolved urgent bugs affecting the client experience
            through defect tracking.
          </li>
        </ul>
      ),
    },
    {
      date: 'May 2021 - Aug 2021',
      title: (
        <span>
          Full Stack Engineer Intern
          <br />
          RemBRAINdt
        </span>
      ),
      description: (
        <ul>
          <li>
            Drove strategy towards modern programming languages and libraries
            through data driven decisions, improving average application speed
            by 37%, and a consensus in improved customer satisfaction
          </li>
          <li>
            Launched MVP in 6 months by turning ideas into actionable plans
            through leading sprints, prioritization, assessing progress, risk
            management, and design sessions.
          </li>
        </ul>
      ),
    },
    {
      date: 'Sept 2019 - Apr 2021',
      title: (
        <span>
          Software UI Lead
          <br />
          Relectric Car
        </span>
      ),
      description: (
        <ul>
          <li>
            Founding team member and Software Lead. Led and collaborated with 6
            engineers to build a responsive dashboard for an electric converted
            car from design to MVP.
          </li>
          <li>
            Implemented Agile methods to lead the team, planned system design
            sessions, and coordinated communications across cross functional
            teams.
          </li>
          <li>
            Implemented a migration from Python to Javascript, improving speed
            and hardware compatibility.
          </li>
        </ul>
      ),
    },
  ];
  return (
    <div className='container'>
      <br />
      <br />
      <br />
      <div className='section-title'>Experience</div>
      {experienceData.map((experience) => (
        <div className='experience-item'>
          <div className='duration'>{experience.date}</div>
          <div className='title'>{experience.title}</div>
          <div className='description'>{experience.description}</div>
        </div>
      ))}
    </div>
  );
};

export default Experience;
