import './about.css';

const About = () => {
  return (
    <div className='about'>
      Familiar with playing all roles on a digital team, from engineer to
      designer to product manager, I’m ultimately passionate about finding
      vision through chaos to build unforgettable user experiences.
      <br />
      <br />
      I'm a University of Calgary Software Engineering graduate with a minor in
      biomedical engineering, with 2+ years of experience shipping innovative
      products. I specialize in designing elegant solutions that bridge the gap
      between technology and humanity, optimizing for shippability and
      scalability while maximizing the user experience.
    </div>
  );
};
export default About;
