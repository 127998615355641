import './skills.css';
import React, { useState } from 'react';
import Accordion from './SkillAccordion';

const Skills = () => {
  const [showSkills, setShowSkills] = useState(false);
  const [moreLess, setMoreLess] = useState('VIEW MORE');
  const onClick = () => {
    setShowSkills(!showSkills);
    if (moreLess == 'VIEW MORE') {
      setMoreLess('VIEW LESS');
    } else setMoreLess('VIEW MORE');
  };

  const skillsData = [
    {
      title: 'Product Management',
      description: (
        <ul>
          <li>Kanban + Jira</li>
          <li>Agile Methodologies</li>
          <li>Data Analysis</li>
          <li>Market Research</li>
          <li>Product Roadmapping</li>
          <li>Product Analytics</li>
          <li>Communication</li>
        </ul>
      ),
    },
    {
      title: 'Software Development',
      description: (
        <ul>
          <li>Javascript + Typescript</li>
          <li>React + Angular</li>
          <li>HTML/CSS</li>
          <li>Swift</li>
          <li>Kotlin</li>
          <li>Java</li>
          <li>C/C++</li>
          <li>Python</li>
        </ul>
      ),
    },
    {
      title: 'UI/UX',
      description: (
        <ul>
          <li>Figma</li>
          <li>Adobe Suite (Photoshop, Illustrator)</li>
          <li>User Centered Design</li>
          <li>Design Principles</li>
          <li>Creativity and Eye for Design</li>
        </ul>
      ),
    },
  ];

  return (
    <div className='skills-container'>
      <div className='section-title'>Specialties</div>
      <div className='accordion'>
        {skillsData.map((skill) => (
          <Accordion title={skill.title} description={skill.description} />
        ))}
      </div>
    </div>
  );
};

export default Skills;
