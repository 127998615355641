import './education.css';

const Education = () => {
  const educationData = [
    {
      title: 'Bachelor in Software Engineering',
      content: 'Minor in Biomedical Engineering',
      date: 'Apr 2023',
    },
    {
      title: 'Foundations of Project Management',
      content: 'Issued by Google via Coursera',
      date: 'Mar 2023',
    },
    {
      title: 'Blockchain Builder',
      content: 'Bootcamp issued by NEAR',
      date: '2021',
    },
  ];

  return (
    <div className='container'>
      <div className='section-title'>Education</div>
      <div className='education-container'>
        {educationData.map((education) => (
          <div className='education-item'>
            <div className='education-title'>{education.title}</div>
            <br />
            <div className='education-date'>{education.date}</div>
            <div>{education.content}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Education;
