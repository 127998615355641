import './App.css';
import Page from './components/Page';

const App = () => {
  return (
    <div className='App'>
      <div className='top-bar'>
        <div className='logo'>Chanelle</div>
        <div>
          <a
            href='https://ca.linkedin.com/in/chanellec'
            target='_blank'
            rel='noopener noreferrer'
            className='social-link'
          >
            LinkedIn
          </a>
          <a
            href='https://github.com/b4yc'
            target='_blank'
            rel='noopener noreferrer'
            className='social-link'
          >
            Github
          </a>
        </div>
      </div>
      <Page />
      <div className='footer'>
        <a href='mailto:chanellebayleec@gmail.com'>Get in Touch.</a>
      </div>
    </div>
  );
};

export default App;
