import './page.css';
import Experience from './Experience';
import Intro from './Intro';
import About from './About';
import Skills from './Skills';
import Projects from './Projects';
import Education from './Education';
const Page = () => {
  return (
    <div className='main'>
      <Intro />
      <About />
      <Experience />
      <Education />
      <Skills />
      <Projects />
    </div>
  );
};

export default Page;
