import React from 'react';
import 'aos/dist/aos.css';
import 'react-slideshow-image/dist/styles.css';
import './projects.css';
import Project from './Project';

const Projects = () => {
  const projectData = [
    {
      title: 'Visualization Platform',
      description:
        'As a product manager and engineer, I collaborated with 4 engineers to build a full stack aggregration and visualization project from scratch, achieving adoption by sponsored company to be used by 100+ employees and local hospitals. Set up regular stakeholder meetings to align project mission and goals. Led Agile sprint planning, retrospectives, and monitored Jira board to track team progress and timeline. Also led API design sessions, clarifying requirements amongst the cross functional. Developed the front end application using Typescript and React. [2023]',
      link: 'https://www.circlecvi.com',
    },
    {
      title: 'This Website',
      description:
        'This website is developed using Javascript, HTML/CSS, and React. A personal project to act as a portfolio that showcases my skills, experience, and recent works. I took the initiative to create my own website to improve my web development skills and encourage constant growth. [2023]',
      link: 'http://chanelle.me',
    },
    {
      title: 'Spacestagram',
      description:
        'An application that consumes NASA\'s API to retrieve their photos of the day. You can infinitely browse through photos, read the background about it, "like" your favourite images to add it to your collection, and share the images with friends! Need to take a coffee break from scrolling through space? Go ahead, this application utilizes browser caching to save your favourite images even if you leave the page. [2022]',
      link: 'https://b4yc.github.io/spacestagram/',
    },
    {
      title: 'NearFunding',
      description:
        'Developed a full stack crowdfunding application built on the NEAR block-chain protocol to exchange cryptocurrency. Developed and deployed smart contracts written in AssemblyScript to allow linking of wallets to the application and securely transfer NEAR coins between wallets. [2022]',
      link: 'https://github.com/b4yc/nearfunding',
    },
    {
      title: 'Shoppies',
      description:
        "Web app that searches the OMDB API for movies for a voting competition. It allows you to save up to 5 of your favourite movies that you'd like to nominate. This app utilizes cookies to store the state of the application in case the user would like to return. [2021]",
      link: 'http://b4yc.github.io/shoppies',
    },
  ];

  return (
    <div>
      <br />
      <br />
      <div className='section-title'>Notable</div>
      {projectData.map((project, index) => (
        <div>
          <Project
            index={`0${index + 1}`}
            title={project.title}
            content={project.description}
            url={project.link}
          />
        </div>
      ))}
    </div>
  );
};

export default Projects;
